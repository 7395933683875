@import "../../vars.module.scss";

.container-user {
  padding: 40px;
  color: #000000;

  .user-name {
    color: $turivius_light;
    font-weight: bold;
    font-size: 15px;
  }

  .user-menu {
    margin: 40px 0px;
  }
}

.btn-action {
  background-color: #def0fe;
  color: $turivius-light;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover,
  &:focus {
    color: $turivius-light;
    background-color: #def0fe;
  }
}
