@import "../../vars.module.scss";

.container-company {
  padding: 40px;
  color: #000000;

  .company-name {
    color: $turivius_light;
    font-weight: bold;
    font-size: 15px;
  }

  .rs-grid-container {
    margin: 0;

    .rs-col {
      padding-left: 0;
    }
  }

  .company-menu {
    margin: 40px 0px;
  }

  .company-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .company-header-title {
      span {
        color: $turivius_light;
        font-weight: bold;
        font-size: 15px;
      }
    }
    .company-header-action {
      width: 15%;
    }
  }

  .company-body {
    height: 100vh;

    .rs-table-cell-content {
      display: flex;
      align-items: center;
    }

    .rs-table-cell-content {
      font-size: 15px;
    }
  }
}
