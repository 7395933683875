@import "../../../vars.module.scss";

.user-collection-container {
  .rs-table-cell-content {
    display: flex;
    align-items: center;
  }

  .rs-table-cell-content {
    font-size: 15px;
  }
}
