// Centraliza o logo na tela e cobre tudo
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out;
  z-index: 9999; // Garante que fica sobre tudo
}

// Estado visível (padrão ao carregar)
.loading-container.visible {
  opacity: 1;
  visibility: visible;
}

// Estado oculto (após o tempo)
.loading-container.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none; // Previne cliques na área oculta
}

// Animação do logo
.loading-logo {
  width: 550px;
  animation: fadeIn 1.5s ease-in-out infinite alternate, scaleUp 1s ease-in-out infinite alternate;
}

// Efeito de Fade-in (suavidade na aparição)
@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

// Efeito de Zoom leve no logo
@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}



//----------------------------------------------------------------------------------------------------

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rs-icon {
  width: 16px !important;
  height: 16px !important;
}

.rs-container {
  flex-direction: row;
}

.tv-btn-modal-default {
  background-color: #cb4420;
  border-radius: 4px;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #e95f39;
    color: #fff;
  }
}

.tv-modal-default {
  &_header {
    padding: 0 0 0 23px;

    &_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #cb4420;
      /* Primary/Main */
    }

    &_subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      // margin-top: 20px;
      color: #475467;
      /* Gray/Gray-600 */

      b {
        font-weight: 600;
      }
    }
  }

  &_body {
    padding: 23px;
    display: flex;
    flex-direction: column;

    &_tab-menu {
      text-align: center;
      padding: 0 128px;
    }

    &_content {
      margin-top: 20px;
    }

    &_label {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &_input {
      margin-bottom: 15px;
    }

    .rs-input {
      border-radius: 4px;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &_footer {
    padding: 20px 13px 21px 15px;
    display: flex;
    gap: 10px;

    &_button {
      background-color: #61dafb;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin-top: 0px !important;
      padding: 8px;
      font-weight: 500 !important;

      &.export {
        padding: 0;
        background-color: transparent;
      }

      &.confirm {
        background-color: #cb4420;
        color: #fff;
      }
      &.cancel {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #d0d5dd !important;
        color: #475467;
      }
    }
  }
}
