@import "../../vars.module.scss";

.btn-confirm {
  background-color: #def0fe;
  color: $turivius-light;
  border-radius: 4px;

  &:hover,
  &:focus {
    color: $turivius-light;
    background-color: #def0fe;
  }
}

.btn-cancel {
  background-color: #ffe2da;
  color: $red;
  border-radius: 4px;

  &:hover,
  &:focus {
    color: $red;
    background-color: #ffe2da;
  }
}
