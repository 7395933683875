@import "../../../vars.module.scss";

.user-history-container {
  .rs-table-cell-content {
    display: flex;
    align-items: center;
  }

  .rs-table-cell-content {
    font-size: 15px;
  }

  .btn-history-action {
    text-decoration: none;
    background-color: $turivius-light;
    color: $white;
    font-weight: 500;

    &:hover {
      background-color: $turivius;
      color: $white;
    }
  }
}
