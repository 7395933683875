@import "../../../vars.module.scss";

.grid-container-user-list {
  .rs-panel-body {
    padding: 0;
  }
  label {
    color: $turivius;
    font-weight: bold;
  }

  .container-load-more {
    display: flex;
    justify-content: center;

    .btn-load-more {
      border-radius: 4px;
      background-color: $turivius-light;
      color: $white;
    }
  }

  .users-information {
    color: $gray;
    padding: 5px;
  }
}

.container-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: white;

  span {
    color: $gray;
    margin-bottom: 20px;
  }
}
