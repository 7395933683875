@import "../../vars.module.scss";

.btn-change-office {
  background-color: $secondary_light;
  border-radius: 0;

  &:hover,
  &:focus {
    background-color: $secondary_dark;
  }
}
