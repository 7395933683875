@import "../../../vars.module.scss";

.user-permissions-container {
  //background-color: $body-color;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 100px;

  .config-title {
    font-weight: bold;
    color: $turivius;
  }

  .service-header {
    .service-title {
      color: $turivius;
      font-size: 18px;
      font-weight: bold;
      width: 100%;
      height: 100%;
    }
  }

  .clickable-selection {
    background-color: #f7f7fa;
    color: gray;
    padding: 2px;
    border-radius: 5px;
    margin-top: 3px;
    width: 100%;
    transition:
      color 0.2s linear,
      background-color 0.3s linear;

    &.disable {
      cursor: pointer;
    }

    &:hover {
      color: #5f5f5f;
      background-color: #e5e5ea;
    }
  }

  .config-session-title {
    font-weight: bold;
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
    color: $turivius;
  }
}

.user-permissions {
  .filter-category {
    border-bottom: 1px solid #d2d4d3;
    padding-bottom: 16px;
    margin-bottom: 16px;

    .config-session-subtitle {
      // font-weight: 500;
      font-size: 13px;
      margin-bottom: 5px;
      color: $turivius-light;
    }

    &_jurimetrics {
      display: flex;
      align-items: center;
      gap: 8px;

      &.np {
        padding: 0;
      }
    }

    &_filters {
      margin-bottom: 16px;

      &_container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          // width: 90%;
        }
      }

      &.np {
        padding: 0;
      }
    }

    &.no-line {
      border-bottom: none;
    }
  }
  .container-filter {
  }
}
