@import "../../vars.module.scss";

.btn-resend {
  background-color: #def0fe;
  color: $turivius-light;
  border-radius: 4px;

  &:hover,
  &:focus {
    background-color: #def0fe;
    color: $turivius-light;
    border-radius: 4px;
  }
}
