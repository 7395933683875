@import "../../vars.module.scss";

.turivius-panel {
  background-color: $white;
  border: 1px solid #e5e5ea;
  margin: 10px 0px;
  border-radius: 8px;

  .turivius-panel-header {
    padding: 20px 5px;

    &.load {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .turivius-panel-body {
    padding: 20px;
    padding-top: 0px;
  }

  .turivius-panel-btn-toggle-expand {
    float: right;
    padding: 9.25px;
    cursor: pointer;
  }

  .turivius-group-actions {
    display: inline-grid;
    position: absolute;
    right: -9%;

    .turivius-group-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      // justify-content: center;
      // align-items: center;
      gap: 5px;
    }

    .turivius-btn-actions {
      // justify-content: center;
      // align-items: center;
      background-color: $secondary_light;
      padding: 0px 8px;
      border-radius: 4px;
      //margin-bottom: 3px;
      margin-left: 0;

      &.redirect {
        background-color: $turivius-light;
      }

      &.eye-slash {
        background-color: $secondary_dark;
      }

      svg {
        color: $white;
      }
    }
  }
}
