@import "../../../vars.module.scss";

.user-monitoring-list {
  .rs-table-row-expanded {
    height: 100px !important;
    max-height: 100px;
    overflow: auto;
    background-color: #fcfcfc;
  }

  .render-row-expanded {
    margin-left: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-bottom: 3px;
    border-bottom: 1px solid #e9e9e9;

    .render-row-expanded-label {
      font-size: 15px;
      color: #aaa;
      margin-bottom: 15px;
    }
  }

  .rs-table-cell-content {
    font-size: 15px;
  }
}
