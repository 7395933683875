@import "../../vars.module.scss";

.btn-action-user {
  background-color: $green;

  .rs-icon {
    background-color: $green;
  }

  &:hover,
  &:focus {
    background-color: $green;

    .rs-icon {
      background-color: $green;
    }
  }
}

.btn-action-company {
  background-color: $green;
  color: white;
  border-radius: 4px;

  &:hover,
  &:focus {
    color: white;
    background-color: $green;
  }
}
