@import "../../vars.module.scss";

.btn-export-data {
  background-color: #def0fe;
  color: $turivius-light;
  border-radius: 4px;

  .rs-icon {
    background-color: #def0fe;
  }

  &:hover,
  &:focus {
    color: $turivius-light;
    background-color: #def0fe;

    .rs-icon {
      background-color: #def0fe;
    }
  }
}
