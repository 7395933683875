@import "../../../vars.module.scss";

.container-account {
  background-color: $white;
  padding: 15px;
  border-radius: 5px;

  .content-information {
    text-align: center;
    padding: 15px 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    transition: transform 400ms ease-in-out;
    background: #ffffff;

    .title-users {
      font-size: 20px;
      color: $secondary_dark;
      font-weight: bold;
    }

    .icon-information {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        90deg,
        #053ac8 0%,
        #0254e1 50.98%,
        #0069f5 100%
      );
      border-radius: 40px 30px 40px 20px;
      position: absolute;
      top: -10%;
      right: -5%;
      width: 60px;
      height: 60px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
      transition: opacity 500ms ease-in-out;
    }

    p {
      padding: 6px 12px;
      margin-top: 5px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 50px;
      letter-spacing: 5px;
    }

    &:hover {
      transform: scale(1.05);
      transition: transform 400ms ease-in-out;
      background: linear-gradient(
        90deg,
        #053ac8 0%,
        #0254e1 50.98%,
        #0069f5 100%
      );

      .icon-information {
        transition:
          opacity 200ms ease-in-out,
          font-size 0ms ease-in-out 500ms;
        opacity: 0;
      }

      .title-users {
        color: $white;
      }

      p {
        color: $white;
      }
    }
  }
}
