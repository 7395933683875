@import "../../vars.module.scss";

.btn-alter-user-status {
  &.active {
    background-color: #d7ffeb;
    color: $green;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;

    .rs-icon {
      background-color: #d7ffeb;
    }
  }

  &.inactive {
    background-color: #ffe2da;
    color: $red;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;

    .rs-icon {
      background-color: #ffe2da !important;
    }
  }
}

.status-button {
  display: flex;
  align-items: center;

  .status {
    font-weight: bold;

    &.ativo {
      text-transform: capitalize;
      color: $green;
    }

    &.inativo,
    &.bloqueado {
      text-transform: capitalize;
      color: $red;
    }
  }
}
