@import "../../vars.module.scss";

.login-container {
  background-color: $sidebar-medium;

  .login {
    width: 100%;

    .shape {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .flag-system {
      user-select: none;
      z-index: 6;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        // font-weight: 700;
        color: #fff;
        letter-spacing: 1px;
        margin-bottom: -10px;
      }
    }
  }
}
