@import "../../vars.module.scss";

.btn-update-bulk {
  background-color: $turivius_light;
  color: $white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .rs-icon {
    background-color: $turivius_light;
  }

  &:hover,
  &:focus {
    background-color: $turivius_light;
    color: $white;

    .rs-icon {
      background-color: $turivius_light;
    }
  }
}
