@import "../../vars.module.scss";

.btn-delete-service {
  color: $red;

  &:hover,
  :focus {
    background-color: $red;
    color: white;
  }
}
