@import "../../vars.module.scss";

.btn-copy-service {
  background-color: $secondary;
  color: $white;

  &:hover,
  &:focus {
    color: $secondary;
    border: 0.5px solid $secondary;
  }
}
