@import "../../vars.scss";

.turivius-checkbox-group-filter {
  .checkbox-input-field {
    margin-top: 8px;
    border: #0000003b solid 1px;
    border-radius: 4px;
    padding: 10px;
    height: 150px;
    width: 100%;
    overflow-y: auto;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .checkbox-group {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      .checkbox-group-category-label {
        margin-right: 8px;
        font-size: 14px;
        text-transform: uppercase;
      }

      .checkbox-group-category-action {
        margin-right: 8px;
        background-color: #0d91fe;
        padding: 0px 5px;
        border-radius: 3px;
        color: #ffffff;
        font-size: 0.7rem;
        cursor: pointer;

        &:hover {
          background-color: #0069f5;
        }
      }
    }

    .checkbox-group-label-container {
      .first-line {
        display: flex;
        align-items: center;

        .new-badge {
          background-color: $secondary-light;
          color: $white;
          border-radius: 2px;
          padding: 0px 2px;
          font-size: 9px;
          margin-left: 0px;
          margin-right: 2px;
          font-weight: 600;
          line-height: 10px;
        }
      }
    }

    .input-field-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #555;
    }

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
  }
}
