@import "../../../vars.module.scss";

.grid-container {
  background-color: $body-color;
  padding: 15px;
  border-radius: 5px;

  label {
    font-size: 15px;
    font-weight: 600;
    color: $turivius;
  }

  .btn-save {
    background-color: $green;
    color: $white;
    border-radius: 4px;
  }

  .btn-edit {
    background-color: $secondary-light;
    color: $white;
    border-radius: 4px;
  }

  .btn-cancel {
    border-radius: 4px;
  }
}
