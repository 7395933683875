@import "../../vars.scss";

.inteiro-teor-filter {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;

  .title-option {
    margin-bottom: 10px;
    color: $black;
    font-weight: 700;
  }

  .rs-radio-group-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;

    .rs-radio-checked {
      background-color: #1675e0;
      border-radius: 5px;

      label {
        color: #ffffff;
      }
    }

    .rs-radio-inline {
      margin-left: 0;
      width: 100%;
      text-align: center;

      .rs-radio-checker {
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        .rs-radio-control {
          display: none;
        }

        label {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }

        @media only screen and (max-width: 1400px) {
          label,
          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
