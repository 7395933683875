@import "../../../vars.module.scss";

.grid-details-container {
  background-color: $body-color;
  padding: 15px;
  border-radius: 5px;

  label {
    font-size: 15px;
    font-weight: 600;
    color: $turivius;
  }

  .btn-edit {
    background-color: $secondary-light;
    color: $white;
    border-radius: 4px;
  }

  .btn-save {
    background-color: $green;
    color: $white;
    border-radius: 4px;
  }

  .btn-edit-cancel {
    border-radius: 4px;
  }

  .btn-icon-company {
    padding: 0;
    margin-left: 5px;
    background-color: transparent;

    .icon-company {
      color: $secondary-light;
      cursor: pointer;
    }
  }
}
