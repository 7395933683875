@import "../../vars.module.scss";

.login-page {
  height: 100vh;
  display: flex;

  //fora

  .rs-flex-box-grid {
    overflow-y: auto;
    flex-direction: column;

    .rs-flex-box-grid-item {
      flex: 0 auto;
      margin-top: 20px;
    }
  }

  .logo-image {
    &.inside {
      width: 100%;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;

      img {
        max-width: 190px;
        max-height: 140px;
      }
    }

    &.outside {
      width: 100%;
      padding: 30px;
      display: flex;
      justify-content: center;

      img {
        max-width: 200px;
        max-height: 150px;
      }
    }
  }

  .box-login-footer {
    background: #ffffff;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    padding: 24px 48px 48px 48px;
  }

  .box-login {
    background: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px !important;
    padding: 24px 48px 48px 48px;

    h4 {
      font-weight: bold;
      color: #000000;
    }

    .rs-form-control-label {
      font-weight: 700;
      color: $sidebar-link-option;
    }

    .rs-input {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #00000033;
      padding: 7px 0;

      &:focus,
      &:focus-within {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .rs-content {
    height: 100%;
  }

  .rs-flex-box-grid {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;

    .rs-flex-box-grid-item {
      max-width: 400px;
    }
  }

  .rs-panel {
    .rs-panel-title {
      h4 {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0px;
      }

      .avatar {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -100px;
        width: 130px !important;
        height: 130px !important;
        border-radius: 50%;
        z-index: 9;
        background: $turivius;
        padding: 15px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

        img {
          width: 100px;
        }
      }
    }

    background: #fff;
  }

  .container-buttons {
    .btn-login {
      background-color: $sidebar-icon;
      color: $white;
      font-weight: bold;
      border-radius: 4px;

      &.again {
        color: $sidebar-link-option;
        background-color: transparent;
      }
    }
    .btn-forgot {
      border-radius: 4px;
      color: $sidebar-link-option;
      font-weight: 700;
      text-align: left;
      padding: 8px 0;
    }
  }
}

@media only screen and (max-width: 420px) {
  .login-page {
    .logo-image {
      &.inside {
        img {
          max-width: 130px;
          max-height: 80px;
        }
      }
    }
    .box-login {
      padding: 30px !important;
    }
  }
}
