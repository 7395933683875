@import "../../vars.module.scss";

.contente-sidebar {
  overflow: hidden auto;
  flex: 0 0 260px;
  background-color: $sidebar-medium;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $sidebar-heavy;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #00010f;
    border-radius: 2px;
  }

  .turivius-sidebar {
    background-color: $sidebar-medium !important;
    z-index: 999;
    padding-top: 24px;
  }
  .turivius-sidenav {
    display: flex;
    flex-direction: column;
    background-color: transparent !important;

    .sidenav-header_logo {
      // background-color: transparent !important;
      padding: 0 !important;
    }

    .rs-btn,
    .rs-btn-default {
      background-color: transparent;
    }

    .sidenav-header_icon {
      color: $white;
      font-size: 24px;
      display: flex;
      justify-content: flex-end;
      padding: 0 16px 8px 0;
    }
  }

  .rs-sidenav-body {
    display: flex;
    flex-direction: column;
  }
  .sidenav-body {
    padding: 24px 24px 0px 24px;

    .turivius-module {
      display: flex;
      margin-bottom: 8px;
      cursor: pointer;

      &_icon {
        font-size: 20px;
        color: $sidebar-heavy;// ícone, mas não vira nada
        margin-right: 12px;
      }

      &_name {
        margin-top: 4px;
        font-weight: 700;
        font-size: 16px;
        color: $sidebar-group-option; // nome dos grandes grupos

        &.active {
          color:  $sidebar-selected; // hover dos texto bold (grandes grupos)
        }
      }
    }

    .turivius-menu_option {
      color: $sidebar-selected !important; // opção selecionada
    }
    .rs-sidenav-item {
      background-color: transparent !important;
      padding: 12px 32px !important;
      color: $simple-text; // texto padrao

      &:hover {
        color:  $sidebar-selected !important; // hover dos textos simples
      }
    }
  }
  .sidenav-footer {
    padding: 24px 24px 24px 24px;

    .turivius-module {
      .turivius-module_icon {
        font-size: 20px;
        color: $sidebar-icon;
        margin-right: 12px;
      }

      .turivius-module_name {
        margin-top: 4px;
        font-weight: 700;
        font-size: 16px;
        color: $sidebar-icon; // Texto sair

        &:hover {
          color: $sidebar-selected !important; // hover sair
        }
      }

      .rs-sidenav-item {
        background-color: transparent !important;
        padding: 8px 0px !important;
        display: flex;
      }
    }
  }

  // formatação rsuite
  .rs-sidenav-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 32px 24px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
  }

  .sidebar-collapsed {
    padding: 24px 0 8px 16px;
    background-color: transparent !important;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;

    .rs-button,
    .rs-btn-default {
      background-color: $turivius-dark;
      color: $white;
    }
  }
}
