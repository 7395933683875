@import "../../vars.module.scss";

.btn-action-user {
  &.body {
    width: 15%;
  }

  .rs-icon {
    background-color: $green;
  }

  &:hover,
  &:focus {
    background-color: $green;

    .rs-icon {
      background-color: $green;
    }
  }
}
