@import "./../../vars.scss";

.rs-header {
  .header-hero {
    width: 100%;

    .rs-panel-body {
      padding: 8px 10px;
    }

    .rs-btn-ghost {
      color: #fff !important;
      border-color: #fff !important;
    }

    .rs-btn-primary:not(.btn-create) {
      background-color: $turivius-dark !important;
      color: #fff !important;

      i {
        background: $turivius-light !important;
      }
    }

    .rs-btn-primary.btn-create {
      background-color: darken($green, 30) !important;
      color: #fff !important;

      i {
        background: $green !important;
      }
    }

    @media only screen and (max-width: 499px) {
      h4,
      h5 {
        font-size: 15px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  .custom-container-header {
    width: 100%;
    max-width: 100%;
    height: 100px;

    overflow: hidden;

    .custom-container-header-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: #fff;
    }

    .header-shape {
      top: 95px;
      position: fixed;
      height: 10px;
      width: 100vw;
      background-color: $body-color;
      border-radius: 6px;
    }
  }
}

.rs-content {
  max-height: 100vh;
  overflow: hidden;

  &.page-content {
    background-color: $white;
    border-left: 1px solid #ccc;
    max-height: calc(100vh - 100px);
    overflow: auto;

    top: -5px;
    position: relative;
    border-top-left-radius: 7px;
  }
}
