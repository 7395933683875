.navbar {
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 15px;

  .rs-nav-item-active {
    font-weight: 700;
  }
}
