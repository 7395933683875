.turivius-academy-container {
  padding: 40px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    h1 {
      font-size: 46px;
      color: black;
    }
  }
}

.questions-filter {
  &_column {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-weight: 500;
    }
  }
}

.list-questions-container {
  .status-color {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_active {
      width: 16px;
      height: 15px;
      border-radius: 50%;
      background-color: #6fff9b;
    }
    &_inactive {
      width: 16px;
      height: 15px;
      border-radius: 50%;
      background-color: #eb5a5a;
    }
  }
}

//CreateUpdateQuestion
.question-action-button {
  background-color: #053ac8;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &.update {
    background-color: #0069f5;

    &:hover,
    &:focus {
      background-color: #3e8bf0;
    }
  }

  &.delete {
    background-color: #f04438;

    &:hover,
    &:focus {
      background-color: #f06a60;
    }
  }

  &:hover,
  &:focus {
    background-color: #0069f5;
    color: #fff;
  }
}
