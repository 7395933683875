@import url("https://fonts.googleapis.com/css2?family=Public+Sans&family=Sora:wght@400;700&display=swap");
@import "./vars.scss";

body {
  font-family: "Public Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
}

.title {
  margin-bottom: 8px;

  label {
    color: $black;
    font-weight: 700;
  }
}

.icon-btn-action {
  border: 1px solid #001645;
  background-color: #ffffff;
  color: #001645;
  letter-spacing: 0.5px;
  font-size: 13px;
  height: 35.4px;
  padding: 0px 16px;

  .rs-icon,
  .rs-icon:hover,
  .rs-icon:focus,
  .rs-icon:active {
    background-color: transparent !important;
  }

  svg {
    height: 1.5em;
  }

  &:hover {
    background-color: #001645;
    color: #ffffff;
  }
}
