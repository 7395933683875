@import "../../vars.module.scss";

.container-users {
  padding: 40px;
  color: #000000;

  .users-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .users-header-title {
      width: 80%;
    }

    .users-header-action {
      width: 15%;

      .rs-icon {
        background-color: $green;
      }
    }
  }

  .users-search {
    margin: 40px 0px;

    .input-search {
      border-radius: 4px;
    }

    .users-search-button {
      background-color: $secondary-dark;
      color: $white;
      border-radius: 4px;
    }
  }

  .users-body {
    height: 100vh;

    .rs-table-cell-content {
      display: flex;
      align-items: center;
      font-size: 15px;
    }
    .rs-table-row {
      cursor: pointer;

      &.rs-table-row-header {
        cursor: default;
      }
    }
  }
}
