@import "../../vars.module.scss";

.container-entities {
	padding: 40px;
	color: #000000;

	.entities-header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 30px;

		span {
			color: $turivius_light;
			font-weight: bold;
		}
	}

	.entities-search {
		margin: 40px 0px;

		.input-search {
			border-radius: 4px;
		}

		.button-search {
			background-color: $secondary-dark;
			color: $white;
			border-radius: 4px;
		}
	}

	.entities-body {
		margin-top: 30px;
		height: 100vh;

		.rs-table-cell-content {
			display: flex;
			align-items: center;
			font-size: 15px;
		}

		.rs-table-row {
			cursor: pointer;

			&.rs-table-row-header {
				cursor: default;
			}
		}

		.rs-table-cell-wrap {
			width: 100%;
		}
	}
}
